import React, { useRef } from "react";
import ReactMapGL, {
  Marker,
  Popup,

  NavigationControl,
} from "react-map-gl";
import "mapbox-gl/dist/mapbox-gl.css";
import { Pin } from "./pin";
import { Box, Hidden } from "@mui/material";
import { MapBoxStyle } from "./style";
import PopupCard from "./popupCard";
import "./map.css"
import { config } from "../../config";
import { LocalStorageKeys } from "../../utils";
import Supercluster from 'supercluster';
import { UseDebounce } from "../customHooks";
import FacilityPopupCard from "./facilityPopupCard";
const supercluster = new Supercluster({
  radius: 128,
  maxZoom: 20,
});
export const RadiusMapBox = ({
  latitude = "",
  longitude = "",
  properties = [],
  updateLocationOnViewChange = () => false,
  updateProperties = () => false,
  km = 20,
  setKms = () => false,
  location = {},
  onView = false, variant = "",
  updateMapViewCard = () => false,
  gobackOnclick = () => false,
  backIcon = false,
  facilityPopUp = false,
  date = "",
  selected = "",
  rootStyle = {},
  searches,
  filters,
  revenue_type,
  types,

}) => {

  // const [km, setKms] = React.useState(5);
  const currentLocation = JSON.parse(localStorage.getItem(LocalStorageKeys.current_location))
  const debounce = UseDebounce()

  // change to see effect

  const [popupInfo, setPopupInfo] = React.useState(null);
  const [openPopUp, setOpenPopUp] = React.useState(false);
  const [viewState, setViewState] = React.useState({
    zoom: 10,
  });

  const mapRef = useRef(null);


  React.useEffect(() => {
    if (latitude?.length === 0 && longitude?.length === 0) {

      if (location) {
        setViewState({ ...viewState, longitude: location?.longitude, latitude: location?.latitude });
      }
      else {
        setViewState({ ...viewState, longitude: currentLocation?.longitude, latitude: currentLocation?.latitude });
      }
      // }
    } else {
      setViewState({
        ...viewState,
        height: "100%",
        width: "100%",
        latitude: latitude,
        longitude: longitude,
      });
    }
    //eslint-disable-next-line
  }, [latitude, longitude]);



  const handleClickMarker = (val) => {

    setPopupInfo(val)
    setOpenPopUp(true)

  }
  const handleClickMarkerClose = (val) => {
    setPopupInfo({})
    setOpenPopUp(false)

  }
  const [points, setPoints] = React.useState([]);
  const [clusters, setClusters] = React.useState([]);
  const [bounds, setBounds] = React.useState([-180, -85, 180, 85]);
  const [zoom, setZoom] = React.useState(0);



  React.useEffect(() => {
    const points = properties?.map((room) => ({
      type: 'Feature',
      properties: {
        cluster: false,
        ...room
      },
      geometry: {
        type: 'Point',
        coordinates: [parseFloat(room.longitude), parseFloat(room.latitude)],
      },
    }));
    setPoints(points);
    //eslint-disable-next-line
  }, [properties]);
  React.useEffect(() => {
    supercluster.load(points);
    setClusters(supercluster.getClusters(bounds, zoom));
    updateMapViewCard(supercluster.getClusters(bounds, zoom));
    //eslint-disable-next-line
  }, [points, zoom, bounds]);

  React.useEffect(() => {
    if (mapRef.current) {
      setBounds(mapRef.current.getMap().getBounds().toArray().flat());
      updateLocationOnViewChange(mapRef.current.getMap().getBounds().toArray().flat())
    }
    //eslint-disable-next-line
  }, [mapRef?.current]);


  //location on change
  const onViewLocationChange = () => {
    debounce(() => updateLocationOnViewChange(mapRef.current.getMap().getBounds().toArray().flat()), 800)
  }

  return (
    <Box style={MapBoxStyle.radiusMain}>

      <ReactMapGL
        {...viewState}
        style={{ ...MapBoxStyle.radiusMap, ...rootStyle }}
        ref={mapRef}
        mapboxAccessToken={config.mapbox_key}
        onViewportChange={(nextViewport) => {
          setViewState(nextViewport)
        }}
        onMove={(evt) => setViewState(evt.viewState)}
        onMoveEnd={(evt) => onViewLocationChange(evt.viewState)}
        mapStyle={config.mapbox_style}
        onZoomEnd={(e) => {
          setZoom(Math.round(e.viewState.zoom))
        }}

      >
        {clusters?.map((cluster) => {
          const { cluster: isCluster, point_count } = cluster?.properties;
          const [longitude, latitude] = cluster?.geometry?.coordinates;

          if (isCluster) {
            return (
              <Marker
                key={`cluster-${cluster?.id}`}
                longitude={longitude}
                latitude={latitude}
              >
                <div
                  className="cluster-marker"
                  style={{
                    width: `${40 + (point_count / points.length) * 20}px`,
                    height: `${40 + (point_count / points.length) * 20}px`
                  }}
                  onClick={() => {
                    const zoom = Math.min(
                      supercluster.getClusterExpansionZoom(cluster?.id),
                      20
                    );
                    mapRef.current.flyTo({
                      center: [longitude, latitude],
                      zoom,
                      speed: 1,
                    });
                  }}
                >
                  {point_count}
                </div>
              </Marker>
            );
          }

          return (
            <>
              <Marker
                key={cluster?.properties?.id}
                longitude={longitude}

                latitude={latitude}
                anchor="bottom"
                onClick={(e) => {
                  e.originalEvent.stopPropagation();
                  handleClickMarker(cluster?.properties)
                }}
              >
                <Pin number={cluster?.properties?.unitsAvailable} />


              </Marker>

              {popupInfo?.id === cluster?.properties?.id && openPopUp &&
                <Popup
                  key={cluster?.properties?.id}
                  anchor="top"
                  longitude={longitude}
                  latitude={latitude}
                  onClose={handleClickMarkerClose}
                  className='popup'
                  closeButton={false}
                >
                  {facilityPopUp ?
                    <FacilityPopupCard popupInfo={popupInfo} onViewButton={onView} date={date} selected={selected}
                      filters={filters}
                      revenue_type={revenue_type}
                      types={types}
                      searches={searches}

                    />
                    :
                    <PopupCard popupInfo={popupInfo} onViewButton={onView} variant={variant} filters={filters}
                      selected={selected}
                      revenue_type={revenue_type}
                      types={types}
                      searches={searches}
                      properties={properties}
                    />
                  }
                </Popup>
              }

            </>
          );
        })}
        <Hidden smDown>

          <NavigationControl position="top-right" showCompass={false} />
        </Hidden>

        {backIcon &&
          <Box sx={MapBoxStyle.arrow} onClick={() => gobackOnclick()}>{backIcon}</Box>
        }


      </ReactMapGL>



    </Box >
  );
};
