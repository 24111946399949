import { IconButton, Box } from "@mui/material";
import React, { useRef } from "react";
import { ScrollMenu, VisibilityContext } from "react-horizontal-scrolling-menu";
import { RightCircleArrow } from "../../assets";
import useDrag from "./drag";
import { sliderStyle } from "./style";
import "./slider.css";
import {
  LeftArrowIcon,
  RightArrowIcon,
} from "../../assets/components/leftArrow";

export const Scroller = ({ children }) => {
  const scrollContainerRef = useRef(null);
  const { dragStart, dragStop, dragMove } = useDrag();

  const handleDrag = (ev) => {
    dragMove(ev, (posDiff) => {
      if (scrollContainerRef.current) {
        scrollContainerRef.current.scrollLeft += posDiff;
      }
    });
  };

  const scrollNext = () => {
    if (scrollContainerRef.current) {
      scrollContainerRef.current.scrollLeft += 300;
      scrollContainerRef.current.scrollbehavior = "smooth";
    }
  };

  const scrollPrev = () => {
    if (scrollContainerRef.current) {
      scrollContainerRef.current.scrollLeft -= 300;
      scrollContainerRef.current.scrollbehavior = "smooth";
    }
  };
  const language = localStorage.getItem("i18nextLng");

  return (
    <ScrollMenu>
      <Box sx={language === "ar" ? sliderStyle.leftRtl1 : sliderStyle.left1}>
        <IconButton onClick={() => scrollPrev()}>
          <LeftArrowIcon />
        </IconButton>
      </Box>
      <Box
        ref={scrollContainerRef}
        onMouseDown={dragStart}
        onMouseUp={dragStop}
        onMouseMove={handleDrag}
        sx={{ overflowX: "auto", display: "flex" }}
      >
        {children}
      </Box>
      <Box sx={language === "ar" ? sliderStyle.rightRtl1 : sliderStyle.right1}>
        <IconButton onClick={() => scrollNext()}>
          <RightArrowIcon />
        </IconButton>
      </Box>
    </ScrollMenu>
  );
};
